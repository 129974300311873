<template>
  <div id="openingList">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>停车记录管理</el-breadcrumb-item>
      <el-breadcrumb-item>手动开闸列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
            </el-input>
          </li>
          <li>
            <span class="label">选择停车场:</span>
            <el-select
              v-model="filtrate.parkingLotId"
              filterable
              placeholder="请选择"
              clearable
              @change="parkingLotChange"
            >
              <el-option
                v-for="item in parkingLotList"
                :key="item.parkingLotId"
                :label="item.parkingLotName"
                :value="item.parkingLotId"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">选择出入口:</span>
            <el-select
              v-model="filtrate.channelId"
              clearable
              placeholder="请选择"
              @change="getList(1)"
            >
              <el-option
                v-for="item in filtrate.passagewayList"
                :key="item.channelId"
                :label="item.channelName"
                :value="item.channelId"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">选择异常类型:</span>
            <el-select
              v-model="filtrate.releaseReason"
              clearable
              placeholder="请选择"
              @change="getList(1)"
            >
              <el-option
                v-for="item of select_releaseReason"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </li>
          <li>
            <span class="label">选择异常类型:</span>
            <el-select
              v-model="filtrate.releaseType"
              clearable
              placeholder="请选择"
              @change="getList(1)"
            >
              <el-option
                v-for="item of select_releaseType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </li>
          <li>
            <span class="label">开始时间:</span>
            <el-date-picker
              v-model="filtrate.startTime"
              type="datetime"
              placeholder="选择开始时间"
              @change="getList(1)"
            >
            </el-date-picker>
          </li>
          <li>
            <span class="label">结束时间:</span>
            <el-date-picker
              v-model="filtrate.endTime"
              type="datetime"
              placeholder="选择结束时间"
              @change="getList(1)"
            >
            </el-date-picker>
          </li>
          <li>
            <el-button type="primary" size="medium" @click="getList(1)"
              >查询
            </el-button>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置
            </el-button>
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              :loading="exportLoading"
              type="primary"
              @click="exportExcel"
              v-if="buttonList.includes('passageway-openingList-export')"
              >导出报表
            </el-button>
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="channelName" label="设备名称" align="center">
        </el-table-column>
        <el-table-column prop="channelType" label="设备类型" align="center">
        </el-table-column>
        <el-table-column
          prop="parkingLotName"
          label="停车场名称"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="username" label="操作账号" align="center">
        </el-table-column>
        <el-table-column prop="concatName" label="联系人" align="center">
        </el-table-column>
        <el-table-column prop="concatPhone" label="联系电话" align="center">
        </el-table-column>
        <el-table-column label="车牌号1" align="center">
          <template #default="scope">
            <span :class="{ differentPlateNum: !scope.row.isSamePlateNum }">{{
              scope.row.plateNum
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="车牌号2" align="center">
          <template #default="scope">
            <span :class="{ differentPlateNum: !scope.row.isSamePlateNum }">{{
              scope.row.plateExtNum
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="totalPrice" label="应付" align="center">
        </el-table-column>
        <el-table-column prop="payAmount" label="实付" align="center">
        </el-table-column>
        <el-table-column prop="releaseType" label="放行类型" align="center">
        </el-table-column>
        <el-table-column prop="releaseReason" label="异常类型" align="center">
          <template #default="scope">
            <span>{{
              scope.row.remark ? scope.row.remark : scope.row.releaseReason
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="orderId"
          label="关联现金支付订单号"
          align="center"
        >
          <template #default="scope">
            <span @click="goToDetail(scope.row.orderId)" class="orderId">{{
              scope.row.orderId
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="imageUrl" label="图片" align="center">
          <template #default="scope">
            <el-image
              v-if="scope.row.imageUrl"
              style="width: 70px; height: 70px"
              :src="scope.row.imageUrl"
              fit="contain"
              :preview-src-list="[scope.row.imageUrl]"
            ></el-image>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column prop="createAt" label="操作时间" align="center">
        </el-table-column>
      </el-table>
      <div
        class="pagination"
        style="justify-content: space-between; align-items: center"
      >
        <ul style="display: flex">
          <li class="totalPrice">
            <strong>应付：</strong>
            <span>{{ table.totalPrice }}</span>
          </li>
          <li>
            <strong>实付：</strong>
            <span>{{ table.payAmount }}</span>
          </li>
        </ul>
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      exportLoading: false,
      filtrate: {
        keyword: "",
        parkingLotId: "",
        releaseType: "",
        channelId: null,
        releaseReason: null,
        passagewayList: [],
        startTime: this.$moment().subtract(14, "days"),
        endTime: new Date(),
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
        totalPrice: 0,
        payAmount: 0,
      },
    };
  },
  computed: {
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
  },
  created() {
    this.getPassagewayList();
    this.getList();
  },
  methods: {
    async exportExcel() {
      try {
        this.exportLoading = true;
        await this.$http.post(
          "/channel/manual/opening/export",
          {
            parkingLotId: this.filtrate.parkingLotId,
            channelId: this.filtrate.channelId,
            releaseReason: this.filtrate.releaseReason
              ? this.filtrate.releaseReason
              : null,
            releaseType: this.filtrate.releaseType
              ? this.filtrate.releaseType
              : null,
            startTime: this.filtrate.startTime,
            endTime: this.filtrate.endTime,
          },
          {
            responseType: "blob",
            timeout: 0,
          }
        );
      } catch {
        this.$message.error("导出失败");
      } finally {
        this.exportLoading = false;
      }
    },
    parkingLotChange() {
      this.getPassagewayList();
      this.getList(1);
    },
    reset() {
      this.filtrate.keyword = "";
      this.filtrate.parkingLotId = "";
      this.filtrate.channelId = "";
      this.filtrate.releaseReason = "";
      this.filtrate.releaseType = "";
      this.filtrate.startTime = "";
      this.filtrate.endTime = "";
      this.getPassagewayList();
      this.getList(1);
    },
    goToDetail(orderId) {
      this.$router.push({
        name: "stopOrderList",
        params: { keyword: orderId },
      });
    },
    async getPassagewayList() {
      try {
        let res = await this.$http.post("/channel/list/all", {
          parkingLotId: this.filtrate.parkingLotId,
        });
        if (res.code === 0) {
          this.filtrate.passagewayList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/channel/manual/opening/list", {
          pageNum: page,
          pageSize: this.table.pageSize,
          keyword: this.filtrate.keyword,
          parkingLotId: this.filtrate.parkingLotId,
          channelId: this.filtrate.channelId,
          releaseReason: this.filtrate.releaseReason
            ? this.filtrate.releaseReason
            : null,
          releaseType: this.filtrate.releaseType
            ? this.filtrate.releaseType
            : null,
          startTime: this.filtrate.startTime,
          endTime: this.filtrate.endTime,
        });
        if (res.code === 0) {
          for (let item of res.data.recordList.list) {
            item.createAt = this.$moment(item.createAt).format(
              "yyyy-MM-DD HH:mm:ss"
            );
            if (!item.plateNum || !item.plateExtNum) {
              item.isSamePlateNum = true;
            } else {
              item.isSamePlateNum = item.plateNum === item.plateExtNum;
            }
          }
          this.table.tableData = res.data.recordList.list;
          this.table.total = res.data.recordList.total;
          this.table.totalPrice = res.data.totalPrice;
          this.table.payAmount = res.data.payAmount;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#openingList {
  .el-table {
    .el-table__body-wrapper {
      .el-table__body {
        tbody {
          .el-table__row {
            .cell {
              .orderId {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .totalPrice {
    margin-right: 20px;
  }
  .pagination {
    & > ul {
      color: $main-color;
    }
  }
}
</style>
